import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  GoAButton,
  GoAContainer,
  GoAFormItem,
  GoAInput,
  GoATextArea,
} from "@abgov/react-components";
import { SessionContext } from "@common-payment-portal/lib";
import {
  RichTextEditor,
  ChipInput,
  JSONEditor,
} from "@common-payment-portal/lib";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "@common-payment-portal/common/logging";
import { createNewPortal } from "../api/admin";

// const statusOptions = [
//   { name: "Draft", value: 0 },
//   { name: "Accepting Application", value: 1 },
//   { name: "Closed For Application", value: 2 },
//   { name: "Finished", value: 3 },
// ];

const schema = Yup.object().shape({
  name: Yup.string()
    .max(200, "should be less than 200 characters")
    .required("Name is required"),
  description: Yup.string().required("Description is required"),
  domainKey: Yup.string()
    .required("Domain Key is required")
    .max(10, "should be less than 10 characters"),
  header: Yup.string()
    .max(200, "should be less than 200 characters")
    .required("Header is required"),
  overview: Yup.string().required("Overview is required"),
  tags: Yup.string(),
  templateJson: Yup.string().required("Template is required"),
});

const PaymentPortal = () => {
  const navigate = useNavigate();
  const { user } = useContext(SessionContext) ?? {};

  const paymentPortalForm = useFormik({
    initialValues: {
      name: "",
      description: "",
      domainKey: "",
      header: "",
      overview: "",
      tags: "",
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      const { success } = await createNewPortal({
        paymentPortal: {
          ...values,
          createdBy: user?.email ?? "dbo",
          updatedBy: user?.email ?? "dbo",
        },
      });
      if (success) {
        navigate("/list");
      }
    },
  });

  const handleChange = (v, t) => {
    paymentPortalForm.setFieldValue(v, t);
  };

  return (
    <div>
      <h1>Payment Portal</h1>
      <div>
        <GoAContainer type="interactive">
          <GoAFormItem
            label="Name"
            requirement="required"
            error={paymentPortalForm.errors.name}
            id="name"
          >
            <GoAInput
              name="name"
              ariaLabelledBy="name"
              width="100%"
              value={paymentPortalForm.values.name}
              onChange={handleChange}
              error={!!paymentPortalForm.errors.name}
            />
          </GoAFormItem>
          <GoAFormItem
            label="Description"
            requirement="required"
            error={paymentPortalForm.errors.description}
            id="description"
          >
            <GoATextArea
              name="description"
              ariaLabelledBy="description"
              width="100%"
              rows={10}
              value={paymentPortalForm.values.description}
              onChange={handleChange}
              error={!!paymentPortalForm.errors.description}
            ></GoATextArea>
          </GoAFormItem>
          <GoAFormItem
            label="Domain Key"
            requirement="required"
            error={paymentPortalForm.errors.domainKey}
            id="domainKey"
            helpText="Max length is 10."
          >
            <GoAInput
              name="domainKey"
              ariaLabelledBy="domainKey"
              width="50%"
              maxLength="10"
              value={paymentPortalForm.values.domainKey}
              onChange={handleChange}
              error={!!paymentPortalForm.errors.domainKey}
            />
            {paymentPortalForm.values.domainKey && (
              <div>
                <i>
                  <u>{`(${paymentPortalForm.values.domainKey}.paymentportal.alberta.ca)`}</u>
                </i>
              </div>
            )}
          </GoAFormItem>
          <GoAFormItem
            label="Header"
            requirement="required"
            error={paymentPortalForm.errors.header}
            id="header"
          >
            <GoAInput
              name="header"
              ariaLabelledBy="header"
              width="100%"
              value={paymentPortalForm.values.header}
              onChange={handleChange}
              error={!!paymentPortalForm.errors.header}
            />
          </GoAFormItem>

          <GoAFormItem
            label="Overview"
            requirement="required"
            error={paymentPortalForm.errors.overview}
            id="overview"
          >
            <RichTextEditor
              value={paymentPortalForm.values.overview}
              onChange={handleChange}
              name="overview"
            />
          </GoAFormItem>

          <GoAFormItem
            label="Tags"
            error={paymentPortalForm.errors.tags}
            id="tags"
          >
            <ChipInput
              value={paymentPortalForm.values.tags}
              onChange={handleChange}
              name="tags"
              placeholder="Add Tags"
            ></ChipInput>
          </GoAFormItem>

          <GoAFormItem
            label="Template"
            requirement="required"
            error={paymentPortalForm.errors.templateJson}
            id="templateJson"
          >
            <JSONEditor
              name="templateJson"
              ariaLabelledBy="templateJson"
              width="100%"
              rows={10}
              value={paymentPortalForm.values.templateJson}
              onChange={handleChange}
              error={!!paymentPortalForm.errors.templateJson}
            ></JSONEditor>
          </GoAFormItem>

          <GoAButton
            type="submit"
            onClick={paymentPortalForm.handleSubmit}
            primary
          >
            Submit
          </GoAButton>
        </GoAContainer>
      </div>
    </div>
  );
};

export default withAITracking(reactPlugin, PaymentPortal);
