import { useRef } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { SetupInterceptors } from "@common-payment-portal/common/apiUtils";
import PaymentPortal from "./admin/PaymentPortal";
import Portals from "./admin/Portals";
import ServerCache from "./admin/ServerCache";
import { FormTest } from "./FormTest";
import ResourceString from "./admin/ResourceString";
import { NotAuthorized, NotFound } from "@common-payment-portal/lib";

export const AppRouter = () => {
  const navigate = useNavigate();
  const runInterceptor = useRef(false);

  /* only run setup once */
  if (!runInterceptor.current) {
    SetupInterceptors(navigate);
    runInterceptor.current = true;
  }

  return (
    <Routes>
      <Route exact path="/" element={<Portals />} />
      <Route exact path="/home" element={<Portals />} />
      <Route exact path="/list" element={<Portals />} />
      <Route exact path="/create" element={<PaymentPortal />} />
      <Route exact path="/resourceString/:id" element={<ResourceString />} />
      <Route exact path="/formTest" element={<FormTest />} />
      <Route exact path="/serverCache" element={<ServerCache />} />
      <Route exact path="/401" element={<NotAuthorized />} />
      <Route exact path="/404" element={<NotFound />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
