import React, { useEffect, useState } from "react";
import {
  GoAButton,
  GoATable,
  GoATableSortHeader,
  GoAInput,
  GoATextArea,
  GoAPagination,
} from "@abgov/react-components";
import { useParams } from "react-router-dom";
import { getResourceString, updateResourceString } from "../api/admin";
import { useCustomQuery } from "@common-payment-portal/common/apiUtils/useCustomQuery";
import { reactPlugin } from "@common-payment-portal/common/logging";
import { withAITracking } from "@microsoft/applicationinsights-react-js";

const ResourceString = () => {
  const { data, isLoading, refetch } = useCustomQuery({
    queryKey: ["getResourceString", "id"],
    queryFn: () => getResourceString(id),
    refetchInterval: 0,
  });

  const [resourceStrings, setResourceStrings] = useState([]);

  // subset of data shown per page
  const [resourceStringsPageList, setResourceStringsPageList] = useState([]);
  // page number
  const [page, setPage] = useState(1);

  const pageCount = 5;

  const sortData = (sortBy, sortDir) => {
    const _resourceStrings = [...(resourceStringsPageList ?? [])];
    _resourceStrings.sort((a, b) => {
      return (a[sortBy] > b[sortBy] ? 1 : -1) * sortDir;
    });
    setResourceStringsPageList(_resourceStrings);
  };

  const changePage = (newPage) => {
    const offset = (newPage - 1) * pageCount;
    const _resourceStringsList = resourceStrings.slice(
      offset,
      offset + pageCount,
    );
    setPage(newPage);
    setResourceStringsPageList(_resourceStringsList);
  };

  const { id } = useParams();

  const updateResource = () => {
    const resouse = [...resourceStrings];

    const test = {
      ResourceStrings: resouse,
    };
    updateResourceString(id, test);
    refetch();
  };

  const handleChange = (name, event, value) => {
    const _resourceStrings = [...(resourceStrings ?? [])];
    _resourceStrings.forEach((curr) => {
      if (curr["name"] === name) {
        curr["values"][event] = value;
      }
    });
    setResourceStrings(_resourceStrings);
  };

  useEffect(() => {
    setResourceStrings(data?.data);
    setResourceStringsPageList(data?.data.slice(0, pageCount));
  }, [data, data?.data]);

  return (
    <div>
      <h1>Resource Strings</h1>

      {!isLoading && (
        <>
          <GoATable onSort={sortData} style={{ float: "left" }}>
            <thead>
              <tr>
                <th>
                  <GoATableSortHeader name="page">Page</GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="group">Group</GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="name">Name</GoATableSortHeader>
                </th>

                <th>
                  <GoATableSortHeader name="title">Title</GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="subTitle">
                    Sub Title
                  </GoATableSortHeader>
                </th>

                <th>
                  <GoATableSortHeader name="placeHolder">
                    Place Holder
                  </GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="errorText">
                    Error Text
                  </GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="errorText">
                    Rich Text
                  </GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="desc">
                    Description
                  </GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="action">Action</GoATableSortHeader>
                </th>
              </tr>
            </thead>
            <tbody>
              {(resourceStringsPageList ?? []).map((resourceString, index) => (
                <tr key={index}>
                  <td>{resourceString["page"]}</td>
                  <td>{resourceString["group"]}</td>
                  <td>{resourceString["name"]}</td>
                  <td>
                    {" "}
                    <GoAInput
                      name="title"
                      id="title"
                      arialabelledby="title"
                      value={resourceString["values"]["title"]}
                      onChange={(e, v) =>
                        handleChange(resourceString["name"], e, v)
                      }
                    >
                      {" "}
                    </GoAInput>{" "}
                  </td>
                  <td>
                    {" "}
                    <GoAInput
                      name="subTitle"
                      arialabelledby="subTitle"
                      value={resourceString["values"]["subtitle"]}
                      onChange={(e, v) =>
                        handleChange(resourceString["name"], e, v)
                      }
                    ></GoAInput>{" "}
                  </td>
                  <td>
                    {" "}
                    <GoAInput
                      name="placeHolder"
                      arialabelledby="placeHolder"
                      value={resourceString["values"]["placeHolder"]}
                      onChange={(e, v) =>
                        handleChange(resourceString["name"], e, v)
                      }
                    ></GoAInput>{" "}
                  </td>
                  <td>
                    {" "}
                    <GoAInput
                      name="errorText"
                      arialabelledby="ErrorText"
                      value={resourceString["values"]["errorText"]}
                      onChange={(e, v) =>
                        handleChange(resourceString["name"], e, v)
                      }
                    ></GoAInput>{" "}
                  </td>
                  <td>
                    {" "}
                    <GoAInput
                      name="richText"
                      arialabelledby="RichText"
                      value={resourceString["values"]["richText"]}
                      onChange={(e, v) =>
                        handleChange(resourceString["name"], e, v)
                      }
                    ></GoAInput>{" "}
                  </td>
                  <td>
                    {" "}
                    <GoATextArea
                      name="description"
                      countby="character"
                      maxcount="20"
                      value={resourceString["values"]["description"]}
                      onChange={(e, v) =>
                        handleChange(resourceString["name"], e, v)
                      }
                    ></GoATextArea>{" "}
                  </td>
                  <td>
                    <GoAButton
                      type="secondary"
                      onClick={() => updateResource()}
                    >
                      {" "}
                      Update
                    </GoAButton>{" "}
                  </td>
                </tr>
              ))}
            </tbody>
          </GoATable>

          <GoAPagination
            itemCount={resourceStrings?.length}
            perPageCount={pageCount}
            pageNumber={page}
            onChange={changePage}
          />
        </>
      )}
    </div>
  );
};

export default withAITracking(reactPlugin, ResourceString);
