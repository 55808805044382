import React, { useEffect, useState } from "react";
import {
  GoABadge,
  GoAButton,
  GoACircularProgress,
  GoASkeleton,
  GoATable,
  GoATableSortHeader,
  GoAPagination,
} from "@abgov/react-components";
import { getPortals, deletePortalById } from "../api/admin";
import { Link } from "react-router-dom";
import { useCustomQuery } from "@common-payment-portal/common/apiUtils/useCustomQuery";
import { reactPlugin } from "@common-payment-portal/common/logging";
import { withAITracking } from "@microsoft/applicationinsights-react-js";

const Portals = () => {
  const { data, isLoading, refetch } = useCustomQuery({
    queryKey: ["portals"],
    queryFn: getPortals,
    refetchInterval: 0,
  });

  const deletePortal = (id) => {
    deletePortalById(id);
    refetch();
  };

  const [portalList, setPortalList] = useState([]);

  // subset of data shown per page
  const [pagePortalList, setPagePortalList] = useState([]);
  // page number
  const [page, setPage] = useState(1);

  const pageCount = 4;

  const sortData = (sortBy, sortDir) => {
    const _portalList = [...(pagePortalList ?? [])];
    _portalList.sort((a, b) => {
      return (a[sortBy] > b[sortBy] ? 1 : -1) * sortDir;
    });
    setPagePortalList(_portalList);
  };

  const changePage = (newPage) => {
    const offset = (newPage - 1) * pageCount;
    const _pagePortalList = portalList.slice(offset, offset + pageCount);
    setPage(newPage);
    setPagePortalList(_pagePortalList);
  };

  const getPortalStatus = (status) => {
    if (status === 0) {
      return <GoABadge type="information" content="DRAFT" />;
    }

    if (status === 1) {
      return <GoABadge type="success" content="Open" />;
    }

    if (status === 2) {
      return <GoABadge type="important" content="Closed" />;
    }

    return <GoABadge type="emergency" content="Finished" />;
  };

  useEffect(() => {
    setPortalList(data?.data);
    setPagePortalList(data?.data.slice(0, pageCount));
  }, [data?.data]);

  return (
    <div>
      <h1>Portals</h1>
      <GoACircularProgress visible={isLoading} variant="fullscreen" />
      {isLoading && <GoASkeleton type="paragraph" />}
      {!isLoading && (
        <>
          <Link to="/create" component={<GoAButton />}>
            Create new record
          </Link>
          <GoATable onSort={sortData}>
            <thead>
              <tr>
                <th>
                  <GoATableSortHeader name="portalStatus">
                    Status
                  </GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="name" direction="asc">
                    Name
                  </GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="header">Header</GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="domainKey">
                    Domain Key
                  </GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="updatedDate">
                    Updated Date
                  </GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="delete">Action</GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="updateResourceString">
                    Resource String
                  </GoATableSortHeader>
                </th>
              </tr>
            </thead>
            <tbody>
              {(pagePortalList ?? []).map(
                ({
                  id,
                  name,
                  domainKey,
                  header,
                  portalStatus,
                  updatedTime,
                }) => (
                  <tr key={id}>
                    <td>{getPortalStatus(portalStatus)}</td>
                    <td>{name}</td>
                    <td>{header}</td>
                    <td>{domainKey}</td>
                    <td>{new Date(updatedTime).toLocaleString()}</td>
                    <td>
                      <GoAButton
                        type="tertiary"
                        onClick={() => deletePortal(id)}
                      >
                        {" "}
                        Delete
                      </GoAButton>{" "}
                    </td>
                    <td>
                      <Link
                        to={`/resourceString/${id}`}
                        component={<GoAButton />}
                      >
                        Update Resource Strings
                      </Link>{" "}
                    </td>
                  </tr>
                ),
              )}
            </tbody>
          </GoATable>
          <GoAPagination
            itemCount={portalList?.length}
            perPageCount={pageCount}
            pageNumber={page}
            onChange={changePage}
          />
        </>
      )}
    </div>
  );
};

export default withAITracking(reactPlugin, Portals);
