import React from "react";
import {
  getLoginEndPoint,
  getLogoutEndPoint,
  getCurrentUrl,
  getLoggedOutEndpoint,
} from "@common-payment-portal/common/apiUtils/auth";

const HeaderComponent = ({ isLoggedIn, user }) => {
  const { fullName, email } = user ?? {};

  return (
    <>
      {isLoggedIn ? (
        <>
          <a
            className="interactive"
            style={{ height: "100%" }}
          >{`${fullName}`}</a>
          <a
            className="interactive"
            href={getLogoutEndPoint(getLoggedOutEndpoint())}
            style={{ height: "100%" }}
          >
            Sign Out
          </a>
        </>
      ) : (
        <a
          className="interactive"
          href={getLoginEndPoint(getCurrentUrl())}
          style={{ height: "100%" }}
        >
          Signin
        </a>
      )}
    </>
  );
};

export default HeaderComponent;