import {
  executePost,
  executeDelete,
} from "@common-payment-portal/common/apiUtils";

export const createNewPortal = async ({ paymentPortal }) => {
  return await executePost("/api/portalAdmin/create", paymentPortal);
};

export const getPortals = async () => {
  return await executePost("/api/portalAdmin/list");
};

export const deletePortalById = async (id) => {
  return await executeDelete(`/api/portalAdmin/deletePortal?id=${id}`);
};

export const getResourceString = async (id) => {
  return await executePost(`/api/resourceString/getByid?id=${id}`);
};

export const updateResourceString = async (id, resourceString) => {
  return await executePost(
    `/api/resourceString/updateString/${id}`,
    resourceString,
  );
};
